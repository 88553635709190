import Preloader from "../Preloader/PreloaderContainer"
import Header from "../Header/HeaderContainer"
import Intro from "../Intro/IntroContainerWrapper"
import Overlay from "../Overlay/OverlayWrapperContainer"
import Speakers from "../Speakers/SpeakersContainerWrapper"
import Calendar from "../Calendar/CalendarContainerWrapper"
import Partners from "../Partners/PartnersContainerWrapper"
import Location from "../Location/LocationContainerWrapper"
import ComponentMount from "../ComponentsMount/ComponentMount"
import About from "../About/AboutWrapperContainer"
import {isInternetExplorer} from "../../utils/checkIE11"
import React, { useState } from "react"


import "./style.css"




const App = () => {

    const [preloader, setPreloader] = useState(true)
    if (preloader) {
        return (
            <>            
                <Preloader  />
                <ComponentMount setPreloader={setPreloader} />
            </>

            
        )
    } else {
        return ( 
            <div className={isInternetExplorer() ? "browser-ie" : "browser-normal"}>   
                
                <Header />
                <Intro />
                <Overlay />
                <About />
                <Speakers />
                <Calendar />
                <Partners />
                <Location />
                {/* <ComponentMount /> */}
            </div>
        )
    }


}

export default App