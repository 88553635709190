
const changeColorBurgerReducer = (state, actions) => {
    if (state === undefined) {
        return "header__nav-trigger"
    }

    switch(actions.type) {
        
        case "CHANGE_COLOR_BURGER": {
            if (actions.payload) {
                return "header__nav-trigger header__nav-trigger--yellow"
            } else {
                return "header__nav-trigger"
            }

        }

        default: {
            return state.classBurger
        }
    }
}

export default changeColorBurgerReducer