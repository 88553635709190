import React, { Component } from "react"
import { connect } from "react-redux"
import {documentLoad} from "../../actions/headsActions"
import {activeBlock} from "../../actions/activeBlock"
import {isInternetExplorer} from "../../utils/checkIE11"

class ComponentMount extends Component {

    componentDidMount() {

        if (isInternetExplorer() ) {
            setTimeout(() => {
                this.props.documentLoad()
                this.props.activeBlock("all")
                this.props.setPreloader(false)
            }, 2000)
        } else {
            setTimeout(() => {
                
                this.props.documentLoad()
                this.props.setPreloader(false)
            }, 1500)
        }

        
    }
    // document.documentElement.clientWidth <= 1200 

    render() {
        return <></>
    }
}

const mapDispatchToProps = {
    documentLoad,
    activeBlock
}


export default connect(null, mapDispatchToProps)(ComponentMount)

