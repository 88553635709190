import React, { Component } from 'react';
import imgMarker from "../../assets/img/pin.png"
import { YMaps, Map, Placemark } from 'react-yandex-maps';


const AnyReactComponent = ({ text }) => <div><img src={imgMarker} alt="marker" /></div>;

class SimpleMap extends Component {

  state = {
    show: false
  }

  componentDidMount() {
    console.log("loooo")
    setTimeout(() => {
      this.setState({
        show: true
      })
    }, 500)
  }

  render() {
    if (this.state.show) {
      return (
        <div  className='map__container'>
          <Map style={{height: "100%", width: "auto"}} defaultState={{ center: [52.2957, 104.3054], zoom: 16 }}>
            <Placemark geometry={[52.2957, 104.3054]} />
          </Map>
        </div>
  
      );
    } else {
      return null
    }

  }
}


export default SimpleMap;
