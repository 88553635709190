import React from "react"

import imgOne from "../../assets/img/t-code.png"
import imgTwo from "../../assets/img/be.png"
import imgThree from "../../assets/img/date.png"



const Intro = ({ scrollHead, introFront, introHead, headOne, headTwo, headThree,
    headFour, headFive, backBe, backMeet, changeClassAnimate }) => {
    return (

        <div className="screen z-7" id="intro">

            <div className="screen__inner screen__inner_default screen__inner_noise" onWheel={scrollHead}>

                <div className="intro">
                    <div className={`front intro__front ${introFront}`}>
                        <div className="leading front__leading">
                            <div className="leading__text">event by</div>
                            <div className="leading__logo"><img src={imgOne} alt="logo" /></div>
                        </div>
                        <div className={`head intro__head ${introHead}`} >
                            <div className={`head__one ${headOne}`}></div>
                            <div className={`head__two ${headTwo}`}></div>
                            <div className={`head__three ${headThree}`}>
                                <div className="head__eyes"></div>
                            </div>
                            <div className={`head__four ${headFour}`}></div>
                            <div className={`head__five ${headFive}`}></div>
                        </div>
                    </div>
                    <div className="back intro__back">
                        <div className="social back__social">
                            <a href="https://vk.com/behanceirkutsk" className="social__icon social__icon_vk">
                                <svg style={{width: "100%", height: "100%"}} viewBox="0 0 192 192" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M125.44 0H66.56C12.8 0 0 12.8 0 66.56V125.44C0 179.2 12.8 192 66.56 192H125.44C179.2 192 192 179.2 192 125.44V66.56C192 12.8 179.2 0 125.44 0ZM153 61C157.234 61 158.124 63.1798 157.234 66.1462C155.454 74.3036 138.397 98.3315 138.397 98.3315C136.914 100.704 136.321 101.891 138.397 104.561C139.128 105.585 140.689 107.113 142.474 108.862C144.31 110.66 146.384 112.692 148.038 114.647C154.052 121.412 158.584 127.119 159.846 131.053C161 135 159 137 155 137H141C137.25 137 135.362 134.9 131.282 130.361C129.552 128.437 127.429 126.075 124.604 123.249C116.297 115.24 112.738 114.202 110.661 114.202C107.843 114.202 107 115 107 119V131.555C107 135 105.899 137 97.0002 137C82.1682 137 65.8686 127.995 54.2998 111.384C36.9465 87.0593 32.2002 68.6675 32.2002 64.9594C32.2002 62.883 33.0002 61 37.0002 61H51.0002C54.5783 61 55.9241 62.566 57.266 66.4427C64.1132 86.3884 75.658 103.82 80.4041 103.82C82.1839 103.82 83.0002 103 83.0002 98.4799V77.8634C82.6789 71.9948 80.6289 69.4569 79.1105 67.577C78.1728 66.4162 77.4379 65.5063 77.4379 64.218C77.4379 62.6522 78.7757 61 81.0002 61H103C105.967 61 107 62.5865 107 66.1462V93.882C107 96.8482 108.288 97.8864 109.178 97.8864C110.958 97.8864 112.441 96.8482 115.704 93.5852C125.79 82.3129 132.909 64.9594 132.909 64.9594C133.799 62.883 135.441 61 139 61H153Z" fill="white" />
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/true.code/" className="social__icon social__icon_insta">
                                <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.914 9.08284C26.851 7.65772 26.6109 6.87981 26.4108 6.36502C26.1759 5.72928 25.8045 5.15717 25.3179 4.68194C24.8491 4.19582 24.2708 3.82377 23.6358 3.58931C23.1211 3.38924 22.3486 3.14905 20.9186 3.08599C19.3792 3.0172 18.9161 3 15.0029 3C11.0953 3 10.6317 3.0172 9.08711 3.08599C7.66291 3.14905 6.88461 3.38924 6.36938 3.58931C5.73436 3.82377 5.16238 4.19582 4.68784 4.68194C4.19553 5.15144 3.82357 5.72928 3.58917 6.36502C3.38858 6.87981 3.14844 7.65256 3.08597 9.08284C3.01662 10.622 3 11.0915 3 15C3 18.9085 3.01662 19.3722 3.08597 20.9172C3.14844 22.3417 3.38858 23.1202 3.58917 23.6356C3.82357 24.2701 4.19553 24.8428 4.68211 25.3175C5.15092 25.8042 5.72863 26.1762 6.36364 26.4107C6.87888 26.6108 7.65087 26.8515 9.08138 26.914C10.6202 26.9828 11.0896 27 14.9971 27C18.9047 27 19.3678 26.9828 20.9129 26.914C22.3371 26.8515 23.1154 26.6108 23.6301 26.4107C24.9058 25.9183 25.9185 24.9059 26.4108 23.6292C26.6109 23.1145 26.851 22.3417 26.914 20.9114C26.9828 19.3665 27 18.9085 27 15C27 11.0915 26.9828 10.6278 26.914 9.08284ZM14.9914 22.5137C10.838 22.5137 7.46805 19.1429 7.46805 14.9885C7.46805 10.8341 10.838 7.46338 14.9914 7.46338C19.1448 7.46338 22.5148 10.8341 22.5148 14.9885C22.5148 19.1429 19.1448 22.5137 14.9914 22.5137ZM22.8122 8.9229C21.8419 8.9229 21.0556 8.13581 21.0556 7.16586C21.0556 6.19534 21.8419 5.4094 22.8122 5.4094C23.7819 5.4094 24.5688 6.19534 24.5688 7.16586C24.5688 8.13581 23.7819 8.9229 22.8122 8.9229Z" fill="white" />
                                    <path d="M18.4421 18.4401C20.3478 16.5338 20.3478 13.4432 18.4421 11.537C16.5363 9.63072 13.4464 9.63072 11.5406 11.537C9.63479 13.4432 9.63479 16.5338 11.5406 18.4401C13.4464 20.3463 16.5363 20.3463 18.4421 18.4401Z" fill="white" />
                                </svg>

                            </a>
                        </div>
                        <div className={`back__be glitch ${backBe}`}>
                            <img src={imgTwo} alt="Bahance logo" />
                        </div>
                        <div onAnimationEnd={changeClassAnimate} className={`back__meet ${backMeet}`} >
                            <div className="back__date-text">25.05<br></br>16:00</div>
                            <div className="back__date">
                                <img src={imgThree} alt="25.05 в 16:00" />
                            </div>
                            <div className="back__location">ул. Баррикад, 51</div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    )
}

export default Intro